<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                GB WhatsApp Data Recovery Way
              </h1>

              <div class="write-by">
                Aug 5, 2023 By Aamir Khan
              </div>

              <p class="writter-content">
                Many users opt to use GB WhatsApp over the standard version due to its additional
                features, especially for important conversations
                related to work or business. Consequently, the conversations and other data stored
                in GB WhatsApp may hold greater significance. In
                the event that you accidentally delete or lose GB WhatsApp data, there is no need to
                worry. There are several methods available for GB
                WhatsApp data recovery. By following the provided instructions and information, you
                can explore various approaches to recover your
                lost data in GB WhatsApp.
              </p>

              <h1 class="intro-title blog">
                How to Restore Messages in
                <a href="https://gbwhatpro.com/" target="_blank" class="jump-url">GB WhatsApp</a>?
              </h1>

              <p class="writter-content">
                The steps to restore messages of GB WhatsApp on your
                android phone, The steps are following:
              </p>

              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-3.webp"></picture>
              </div>

              <h1 class="intro-title blog">
                For User has Backup
              </h1>

              <p class="writter-content">
                To restore your chat on GB WhatsApp, follow these steps: <br>
                1. Make sure you have a backup of the chat you want to restore.<br>
                2. Locate the chat backup on Google Drive and download the folder to your Android
                phone.<br>
                3. Long-press on the backup folder on your phone and select the "Copy" option.<br>
                4. Navigate to your root directory folder or a local folder and paste the copied
                chat backup folder.<br>
                5. Launch the GB WhatsApp app on your phone and verify your phone number.<br>
                6. Look for the "Backup Available" option and choose the "Restore" option.<br>
                7. The messages will be restored to your GB WhatsApp account from the backup
                folder.<br><br>
                By following these steps, you can successfully restore your chat on GB
                WhatsApp.<br>
              </p>

              <h1 class="intro-title blog">
                For User without Backup
              </h1>

              <p class="writter-content">
                If you do not have backup of the chat, can you restore the messages? <br>
                Yes, but you need to find the third party tool for help. You can search them on
                Google
              </p>

              <h1 class="intro-title blog">
                Where is GB WhatsApp backup?
              </h1>

              <p class="writter-content">
                Go to the storage of your device-> Then opt for the “GB Whatsapp” option -> go to
                the option “DataBase”. You will see your device’s
                list of backup files, pls select the most recent backup. Rename it. Copy the backup
                and paste it into the Whatsapp database folder.
              </p>

              <div class="lazy-picture-container writer-banner full-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-4.webp"></picture>
              </div>

              <h1 class="intro-title blog">
                How to backup GB WhatsApp data on Google Drive?
              </h1>

              <p class="writter-content">
                First, open the application. <br>
                Go to “More options”-> “Settings”->“Chats”-> “Chat Backup”, <br>
                and then choose the option “Back up to Google Drive” and choose the account where
                you would prefer the backing up to take place.
              </p>

              <h1 class="intro-title blog">
                How to Recover Hidden Chats on GB WhatsApp
              </h1>

              <p class="writter-content">
                Hidden chats in GB WhatsApp are conversations that you keep private within the app.
                You have the flexibility to restore or unhide
                these chats whenever you wish to view or access them again. Otherwise, they remain
                concealed and inaccessible to external parties.
                Let's go through the steps to unhide hidden chats on GB WhatsApp, which also serve
                as a guide on how to recover hidden chats. <br>
                1. Launch the GB WhatsApp app on your phone.<br>
                2. Long-press to select the contacts whose chats you want to unhide.<br>
                3. Look for three dots located at the top right corner of the app and tap on
                them.<br>
                4. A drop-down menu will appear. From there, choose the "Unhide Chat"
                option.<br><br>
                By following these steps, you can easily unhide and recover your hidden chats on GB
                WhatsApp.<br>
              </p>

              <h1 class="intro-title blog">
                GB WhatsApp Download and GB Whatsapp Update
              </h1>

              <p class="writter-content">
                Click on the following button to download the GB WhatsApp
              </p>

              <div
                class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
                <div class="wp-block-button">
                  <a id="dl" class="wp-block-button__link wp-element-button" target="_blank"
                    rel="dl noopener" @click="gotodownload()">Download Page</a>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbwhatsapp.chat/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
		HeadNav,
	},
  data () {
    return {
      pageName: 'home',
    };
  },
  mounted () { 
    document.documentElement.lang = 'en';
  },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
