<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <div v-for="(data, i) in reverseDoc" :key="i" class="blog-list-item"
                @click="goto(data.link)">
                <div class="blog-list-img">
                  <img :src="data.poster" alt="">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    <!-- {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }} -->
                    {{ data.date }}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbwhatsapp.chat/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';
import doc from '@/utlis/blogList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      doc: doc,
    };
  },
  mounted () {
    document.documentElement.lang = 'en';
   },
  computed: {
    reverseDoc () {
      return this.doc.bloglist.slice().reverse();
    },
  },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
