<template>
    <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapp.chat/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" width="40" height="40" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapp.chat/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <ul id="menu-primary-menu" class="menu sf-menu mobile-menu-page" v-show="isMenuClicked">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapp.chat/" aria-current="page">{{translation[selectedLanguage].home}}</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://www.gbwhatsapp.chat/downloadpage/">{{translation[selectedLanguage].download}}</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://www.gbwhatsapp.chat/blogs/">{{translation[selectedLanguage].blogs}}</a>
          </li>
        </ul>

      <div id="primary-menu" class="main-nav">
        <div class="mobile-menu" v-if="$global.isMobileDevice()">
          <div class="language-selector">
            <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
              <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang" :label="data.val">
                {{ data.val }}
              </option>
            </select>
          </div>
          <div class="menu-toggle" @click="isMenuClicked=!isMenuClicked">
            <img src="@/assets/menu.svg" alt="menu button" v-if="!isMenuClicked">
            <img src="@/assets/xmark.svg" alt="close button" v-else>
          </div>
        </div>

        <ul v-else id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapp.chat/" aria-current="page">{{translation[selectedLanguage].home}}</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://www.gbwhatsapp.chat/downloadpage/">{{translation[selectedLanguage].download}}</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://www.gbwhatsapp.chat/blogs/">{{translation[selectedLanguage].blogs}}</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <div class="language-selector">
              <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
                <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                  {{ data.val }}
                </option>
              </select>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    selectedLanguage: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      isMenuClicked: false,
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'po',
          val: '🇵🇹 Português'
        },
        {
          lang: 'tr',
          val: '🇹🇷 Türkçe'
        },
        {
          lang: 'in',
          val: '🇮🇳 हिंदी'
        },
      ],
      translation: {
        en: {
          home: 'Home',
          download: 'Download',
          blogs: 'Blogs',
          mod: 'WhatsApp Mods',
        },
        ar: {
          home: 'الصفحة الرئيسية',
          download: 'تحميل',
          blogs: 'المدونات',
          mod: 'مودات واتس اب',
        },
        id: {
          home: 'Beranda',
          download: 'Unduh',
          blogs: 'Blog',
          mod: 'Mod WhatsApp',
        },
        po: {
          home: 'Casa',
          download: 'Baixar',
          blogs: 'Blogs',
          mod: 'Mods do WhatsApp',
        },
        tr: {
          home: 'Ana Sayfa',
          download: 'İndir',
          blogs: 'Bloglar',
          mod: 'WhatsApp Modları',
        },
        in: {
          home: 'घर',
          download: 'डाउनलोड',
          blogs: 'ब्लॉग',
          mod: 'व्हाट्सएप मोड्स',
        },
      },
    }
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      // this.$router.push({
      //   name: `${this.selectedLanguage}-gb`
      // });
      if (this.selectedLanguage === 'en') {
        window.location.href = '/';
      } else{
        window.location.href = `/${this.selectedLanguage}`;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mods {
  position: relative;
  .mod-list{
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 1001;
    background: #212121;
    color: #ffffff;
    .mod-item {
      padding: 12px;
      border-bottom: 1px solid #ffffffac;
      font-size: 15px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #000000;
      }
      .mod-title {
        line-height: 22px;
        padding: 5px 0;
      }
    }
  }
}

.main-nav {
    .menu {
    list-style-type: none !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .inside-navigation {
    position: relative;
    .mobile-menu-page {
      width: 100%;
      position: absolute;
      top:100%;
      z-index: 1001;
      margin: 0 !important;
      .menu-item {
        padding: 10px 0;
        margin-bottom: 0 !important;
        background: #323232;
        a {
          font-size: 15px;
          text-align: center;
          color: #ffffff;
          display: block;
        }
        &.mods {
          position: relative;
          img {
            width: 30px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            padding: 7px;
          }
        }
        &.mod-item {
          background: #434343;
          border: 0;
        }
      }
    }
  }
.main-nav {
  flex: 1;
  .mobile-menu{
    display: flex;
    justify-content: space-evenly;
    position: relative;
    .language-selector {
      width: 60%;
      #language-select {
        width: 100%;
      }
    }
    .menu-toggle {
      display: block;
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
}
</style>
