<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en"/>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                How to use same WhatsApp Account on Different phone?
              </h1>

              <!-- <div class="write-by">
                {{ $global.formatTimestamp(new Date()) }} By Aamir Khan
              </div> -->
              <div class="write-by">
                April 28, 2023 By Aamir Khan
              </div>

              <p class="writter-content">
                If you are using WhatsApp and want to use the same account on a different phone, the
                answer is "no." <br>
                But if you are using GB WhatsApp and want to use the same account on a different
                phone, the answer is "yes."
              </p>

              <div class="lazy-picture-container writer-banner full-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-1.jpg"></picture>
              </div>

              <h1 class="intro-title blog">
                Link your GBWhatsApp Account to multiple Devices
              </h1>

              <p class="writter-content">
                As people's demand for chat continues to increase, the functions of WhatsApp will
                also increase. However, the official WhatsApp does
                not meet all the needs of users, and some necessary functions do not meet those
                needs. Therefore, a third-party development team
                appears and updates the version of WhatsApp with richer content. This is GBWhatsApp.
              </p>

              <h1 class="intro-title blog">
                Why are Many People Using
                <a href="http://gbwhatsapk.org" target="_blank" class="jump-url">GB WhatsApp</a>?
              </h1>

              <p class="writter-content">
                Step1: Open GBWhatsApp on your main smartphone. <br>
                Step2: Tap the ⋮ overflow menu.<br>
                Step3: Tap Linked devices.<br>
              </p>

              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-2.webp"></picture>
              </div>

              <p class="writter-content">
                Step4: Tap the green Link a device button. <br>
                Step5: Scan the QR code displayed on your other phone.<br>
                Step6: Alternatively, select <strong>Link with phone number instead</strong>. Open
                <a href="https://web.whatsapp.com/">WhatsApp GB</a> on the secondary phone and enter
                your phone number to receive a one-time passcode
                to link both devices.<br>
                Step7: Your secondary phone starts synchronizing your messages.
              </p>

              <h1 class="intro-title blog">
                Enjoy GB WhatsApp from two (or more) phones
              </h1>

              <p class="writter-content">
                GBWhatsApp allows you to utilize GB WhatsApp on multiple phones simultaneously, up
                to a maximum of five devices. The best part is that
                you can use GB WhatsApp on your secondary phone even without a SIM card. With this
                feature, you can fully enjoy the functionalities of
                GB WhatsApp on your secondary device, including sending and receiving messages,
                images, and videos. Additionally, you can make and
                receive voice and video calls through GB WhatsApp on your secondary phone. And if
                you're looking for more, here are some handy tips
                and tricks to enhance your GB WhatsApp experience.
              </p>

              <h1 class="intro-title blog">
                Will my message History appear on all my linked devices?
              </h1>

              <p class="writter-content">
                When you link your primary phone to another device, GBWhatsApp will send an
                encrypted copy of your recent message history. This data
                is stored locally on each device. So you can allow it to copy that for your current
                one. If you do not see an older message on a
                companion device, you should be able to find the message on your primary phone.
              </p>

              <h1 class="intro-title blog">
                GB WhatsApp Download and GB Whatsapp Update
              </h1>

              <p class="writter-content">
                Click on the following button to download the GB WhatsApp
              </p>

              <div
                class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
                <div class="wp-block-button">
                  <a id="dl" class="wp-block-button__link wp-element-button" target="_blank"
                    rel="dl noopener" @click="gotodownload()">Download Page</a>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://www.gbwhatsapp.chat/privacy/">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbwhatsapp.chat/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
		HeadNav,
	},
  data () {
    return {
      pageName: 'home',
    };
  },
  mounted () { 
    document.documentElement.lang = 'en';
  },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
