export default{
  bloglist: [
    {
      title: 'How to use same WhatsApp Account on Different phone?',
      poster: require('@/assets/blog-1.jpg'),
      desc: "If you are using WhatsApp and want to use the same account on a different phone, the answer is 'No' But if you are using GB WhatsApp and want to use the same account on a different phone, the answer is ",
      link: '/blog-1/',
      date: '2023-04-28'
    },
    {
      title: 'GB WhatsApp Data Recovery Way',
      poster: require('@/assets/blog-3.webp'),
      desc: 'Many users opt to use GB WhatsApp over the standard version due to its additional features, especially for important conversations related to work or business. Consequently, the conversations and other data stored in GB Whats',
      link: '/blog-2/',
      date: '2023-08-05'
    },
    {
      title: 'GBWhatsApp Channels',
      poster: require('@/assets/blog-cv-3.webp'),
      desc: "Great news, channel function is finally here on GBWhatsApp. It's like Telegram Channel, where you can share information and connect with your audience. That means you can enjoy all the channel goodness right from your favorite modified WhatsApp version. So, let's dive into the world of WhatsApp channels and discover how they can revolutionize your chatting experience and boost your business.",
      link: '/blogs/GBWhatsApp-Channels/',
      date: '2023-12-11'

    },
    {
      title: 'All Your Questions About GB WhatsApp',
      poster: require('@/assets/blog-cv-4.webp'),
      desc: 'GB WhatsApp is also known as " WhatsApp GB", "Ogmods" app which has more unique features of WhatsApp. These include features such as attractive themes, the ability to hide internet status, enabling airplane mode, and more.',
      link: '/blog-4/',
      date: '2024-03-23'

    },
    {
      title: "The 10 Best Apps for Spying someone else's WhatsApp",
      poster: require('@/assets/OTgyNGRhYzA4ZDU2NGEyYjM3ZjU2MDE0NzE4ZDIxZDJfeGNTRDRhandPMndkTUF1V2MwQVNreDZLdExXMUJPMGdfVG9rZW46SUt2SWJHOTVOb3hpR0t4aUdYTmMwMDBCbm9nXzE3MTg3ODM1MjA6MTcxODc4NzEyMF9WNA.webp'),
      desc: 'This is one of the most frequently asked questions on social media platforms. It is evident that, regardless of the reasons, people are very eager to know how to spy',
      link: '/blog-5/',
      date: '2024-09-02'

    },
    {
      title: "Error: You Need The Official WhatsApp to Use This Account",
      poster: require('@/assets/blog6-1.webp'),
      desc: "Have you ever received this error message: \"You Need The Official WhatsApp to Use This Account\"? Many users of WhatsApp Mods like FM WhatsApp, WhatsApp Plus, OB WhatsApp, and other mods with smaller user bases have encountered this issue.",
      link: '/fix-you-need-official-whatsapp/',
      date: '2024-11-06'
    },
    {
      title: "How to Hide Online Status on GB Whatsapp",
      poster: require('@/assets/blog7-1.webp'),
      desc: "GB WhatsApp is a popular modified version of the official WhatsApp application, offering enhanced features to help users hide online status. Unlike the original app",
      link: '/how-to-hide-online-status-on-gb-whatsapp/',
      date: '2024-01-23'
    },

  ]
}

